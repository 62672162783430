import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { DataPropsListGeneric } from "../contracts/data-props-list"
import MainCard from "../components/main-card"
import { MarkdownRemark } from "../contracts/markdown-remark"

interface SakramentyMarkdownRemark extends MarkdownRemark {
  frontmatter: {
    title: string
    date?: string
    thumbnail?: string
    layout: string
  }
}

const Sakramenty: React.FC<
  PageProps<DataPropsListGeneric<SakramentyMarkdownRemark>>
> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || "Tytuł"
  const posts = data.allMarkdownRemark.nodes
  const pageTitle = "Sakramenty"

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title={pageTitle} />
        <p>Nie ma żadnych wpisów.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={pageTitle} />
      <div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto py-1 sm:py-2 lg:py-3 lg:max-w-none">
            <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-6 gap-y-10">
              {posts.map(post => {
                return (
                  <MainCard
                    key={post.fields.slug}
                    thumbnail={post.frontmatter.thumbnail}
                    title={post.frontmatter.title || post.fields.slug}
                    date={post.frontmatter.date}
                    slug={post.fields.slug}
                    description={post.excerpt}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sakramenty

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "sakramenty" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM YYYY", locale: "pl")
          title
          thumbnail
          layout
        }
      }
    }
  }
`
