import { Link } from "gatsby"
import * as React from "react"
import { MainCardProps } from "../contracts/main-card-props"

export default function MainCard(props: MainCardProps) {
  const image = props.thumbnail ? (
    <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
      <img
        src={props.thumbnail}
        alt={props.title}
        className="w-full h-full object-center object-cover"
      />
    </div>
  ) : null
  const link = props.slug ? (
    <Link to={props.slug}>
      <span className="absolute inset-0"></span>
    </Link>
  ) : null
  return (
    <div className="group relative card">
      {image}
      <h3 className="mt-5 text-sm text-gray-500">{link}</h3>
      <p className="mb-2 font-semibold leading-tight text-2xl text-gray-800">
        {props.title}
      </p>
      <hr
        className="border-gray-200 my-1 border-bottom-none"
        style={{ borderTopWidth: 0 }}
      ></hr>
      <p
        className="mt-3 text-gray-700 text-base"
        dangerouslySetInnerHTML={{
          __html: props.description || "",
        }}
        itemProp="description"
        style={{ whiteSpace: "pre-line" }}
      />
      <div className="flex text-gray-500 text-xs">
        <div className="pr-3">{props.date}</div>
      </div>
    </div>
  )
}
